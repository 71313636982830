@media (max-width: 641px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */ 

	.contact-container {
		position: absolute;
		top: 0;
		left: 0;
		
		width: 100vw;
		height: calc(100vh + 70px);
		overflow: hidden;
	}

	.contact-lottie-container {
	  position: sticky;
	  z-index: -1;
	  top: 70px;
	  left: 0px;

	  height: calc(100vh - 70px);
	  width: 260vw;

	  margin-left: -80vw;
	}

	.contact-form {
		position: absolute;
		top: 70px;
		left: 0px;

		margin: 10px;
		padding: 10px;
		width: calc(100vw - 20px);
		height: calc(100vh - 90px);

		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 0px 10px;

		border-radius: 20px;
		border: 2px solid #fff;
		background-color: rgba(250,250,250,0.4);
		background-color: rgba(20,20,20,0.4);

	}

		.contact-project-title {
			grid-column-start: 1;
			grid-column-end: 3;

			padding: 0px 10px;
			padding-bottom: -6px;
			margin-bottom: 10px;

			color: #fff;
	    font-size: 24px;
	    font-family: 'Ubuntu', sans-serif;

			border-bottom: 1px solid #ccc;
		}

		.contact-form > div {
			position: relative;
		}

		.contact-form label {
			position: relative;
			padding-left: 10px;

			color: #fff;
	    font-size: 16px;
	    font-family: 'Ubuntu', sans-serif;
		}
		
			.contact-form-input {
				margin-top: 4px;
				padding: 0px 10px;
				min-height: 30px;
				width: 100%;

				outline: none;
				border: 2px solid #fff;
				border-radius: 20px;
				background-color: rgba(250,250,250,0.2);

				color: #fff;;
	    	font-size: 14px;
	    	font-family: 'Menlo', sans-serif;
			}

			.contact-form textarea {
				line-height: 28px;
				border-radius: 20px;
			}
		
				.contact-form-input:hover,
				.contact-form-input:focus {
					border: 2px solid #00ccbe;

					background-color: rgba(0,204,190,0.3);
				}

			.contact-form-remote {
				display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        height: 30px;
        margin-top: 4px;
			}
			
				.contact-form-remote label {
					width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
				}

			.contact-budget-wrapper {
				height: 36px;
			}

				.contact-budget {
					display: flex;
					align-items: center;

					padding: 0px 10px;
					height: 36px;
					width: 100%;

					outline: none;
					border: 2px solid #fff;
					border-left: none;
					border-top-right-radius: 20px;
					border-bottom-right-radius: 20px;
					background-color: rgba(250,250,250,0.2);

					color: #fff;;
		    	font-size: 14px;
		    	font-family: 'Menlo', sans-serif;
				}

					.contact-budget:hover,
					.contact-budget:focus {
						border: 2px solid #00ccbe;
						border-left: none;

						background-color: rgba(0,204,190,0.3);
					}

			.contact-form-note {
				height: 100%;

				display: flex;
				align-items: flex-end;
				justify-content: flex-start;
			}

				.contact-form-note p { 
					color: rgb(220,220,220);
			    font-size: 12px;
			    font-weight: bold;
			    font-family: 'Menlo', sans-serif;
				}

					.contact-form-note span { 
						color: red;
					}

			.contact-form-submit {
				margin-top: 10px;
				display: flex;
				justify-content: flex-end;
			}

			.contact-form-submit > button {
				cursor: pointer;
				height: 40px;
				width: 160px;

				display: flex;
				align-items: center;
				justify-content: center;
				
				outline: none;
				border: 2px solid #5c0f8b;
				border: 2px solid #fff;
				border-radius: 50px;
				
				background-color: transparent;
				background-color: #5c0f8b;
				
				color: #fff;
		    font-size: 18px;
		    font-weight: bold;
		    font-family: 'Ubuntu', sans-serif;
			}
			
				.contact-form-submit > button:hover {
					border: 3px solid #5c0f8b;
					background-color: rgb(250,250,250);
					color: #5c0f8b;
					font-weight: bold;
				}

			.contact-form-submit > div {
				cursor: default;
				height: 50px;
				width: 160px;

				display: flex;
				align-items: center;
				justify-content: center;
				
				outline: none;
				border: 2px solid rgb(100,100,100);
				border-radius: 50px;
				
				background-color: rgb(100,100,100);
				
				color: #ccc;
		    font-size: 22px;
		    font-family: 'Ubuntu', sans-serif;
			}
		
		.contact-single-line {
			grid-column-start: 1;
			grid-column-end: 3;
		}

		.contact-title {
			grid-column-start: 1;
			grid-column-end: 3;

			padding: 0px 10px;
			padding-bottom: -6px;
			margin-bottom: 10px;

			display: grid;
			grid-template-columns: 70% auto;

			border-bottom: 1px solid #ccc;
		}

			.contact-title > span {
				height: 100%;

				display: flex;
				align-items: center;

				color: #fff;
		    font-size: 22px;
		    font-family: 'Ubuntu', sans-serif;
			}
			
			.contact-title > button {
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				outline: none;
				border: none;
				background-color: transparent;

				color: #fff;
				color: #00ccbe;
		    font-size: 16px;
		    font-family: 'Ubuntu', sans-serif;
			}
				
				.contact-title > button:hover {
					color: #ffdc32;
				}
}

@media (max-width: 961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width: 961px)  { 
  /* desktops */ 

	.contact-container {
		position: absolute;
		top: 0;
		left: 0;
		
		width: 100vw;
		height: 100vh;
	}

	.contact-lottie-container {
	  position: fixed;
	  z-index: -1;
	  top: 0px;
	  left: 0px;

	  width: 150vw;
	}

	.contact-form {
		min-height: calc(100vh - 180px);
		width: 600px;
		margin: 20px 80px;
		padding: 20px;

		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 10px 20px;

		border-radius: 20px;
		border: 2px solid #fff;
		background-color: rgba(250,250,250,0.4);
		background-color: rgba(20,20,20,0.4);

	}

		.contact-project-title {
			grid-column-start: 1;
			grid-column-end: 3;

			padding: 0px 10px;
			padding-bottom: 4px;
			margin-bottom: 14px;

			border-bottom: 1px solid #ccc;

			color: #fff;
	    font-size: 26px;
	    font-weight: bold;
	    font-family: 'Ubuntu', sans-serif;
		}

		.contact-form > div {
			position: relative;
			width: 100%;
		}

		.contact-form label {
			position: relative;
			padding-left: 10px;

			color: #fff;
	    font-size: 16px;
	    font-family: 'Ubuntu', sans-serif;
		}
		
			.contact-form-input {
				margin-top: 8px;
				padding: 0px 10px;
				min-height: 36px;
				width: 100%;

				outline: none;
				border: 2px solid #fff;
				border-radius: 20px;
				background-color: rgba(250,250,250,0.2);

				color: #fff;;
	    	font-size: 14px;
	    	font-family: 'Menlo', sans-serif;
			}

			.contact-form textarea {
				line-height: 28px;
				border-radius: 20px;
			}
		
				.contact-form-input:hover,
				.contact-form-input:focus {
					border: 2px solid #00ccbe;

					background-color: rgba(0,204,190,0.3);
				}

			.contact-form-remote {
				display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        height: 40px;
        margin-top: 4px;
			}
			
				.contact-form-remote label {
					width: 100%;
					height: 100%;
          
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          color: #fff;;
		    	font-size: 14px;
		    	font-family: 'Menlo', sans-serif;
				}

			.contact-budget-wrapper {
				height: 36px;
			}

				.contact-budget {
					display: flex;
					align-items: center;

					padding: 0px 10px;
					height: 36px;
					width: 100%;

					outline: none;
					border: 2px solid #fff;
					border-left: none;
					border-top-right-radius: 20px;
					border-bottom-right-radius: 20px;
					background-color: rgba(250,250,250,0.2);

					color: #fff;;
		    	font-size: 14px;
		    	font-family: 'Menlo', sans-serif;
				}

					.contact-budget:hover,
					.contact-budget:focus {
						border: 2px solid #00ccbe;
						border-left: none;

						background-color: rgba(0,204,190,0.3);
					}

			.contact-form-note {
				height: 100%;

				display: flex;
				align-items: flex-end;
				justify-content: flex-start;
			}

				.contact-form-note p { 
					color: rgb(220,220,220);
			    font-size: 13px;
			    font-family: 'Menlo', sans-serif;
				}

					.contact-form-note span { 
						color: red;
					}

			.contact-form-submit {
				margin-top: 10px;
				display: flex;
				justify-content: flex-end;
			}

			.contact-form-submit > button {
				cursor: pointer;
				height: 50px;
				width: 160px;

				display: flex;
				align-items: center;
				justify-content: center;
				
				outline: none;
				border: 2px solid #5c0f8b;
				border: 3px solid #fff;
				border-radius: 50px;
				
				background-color: transparent;
				background-color: #5c0f8b;
				
				color: #fff;
		    font-size: 22px;
		    font-family: 'Ubuntu', sans-serif;
			}
			
				.contact-form-submit > button:hover {
					border: 3px solid #5c0f8b;
					background-color: rgb(250,250,250);
					color: #5c0f8b;
					font-weight: bold;
				}

			.contact-form-submit > div {
				cursor: default;
				height: 50px;
				width: 160px;

				display: flex;
				align-items: center;
				justify-content: center;
				
				outline: none;
				border: 2px solid rgb(100,100,100);
				border-radius: 50px;
				
				background-color: rgb(100,100,100);
				
				color: #ccc;
		    font-size: 22px;
		    font-family: 'Ubuntu', sans-serif;
			}
		
		.contact-single-line {
			grid-column-start: 1;
			grid-column-end: 3;
		}

		.contact-title {
			grid-column-start: 1;
			grid-column-end: 3;

			padding: 0px 10px;
			padding-bottom: 4px;
			margin-bottom: 14px;

			border-bottom: 1px solid #ccc;

			display: grid;
			grid-template-columns: 80% auto;
		}

			.contact-title > span {
				color: #fff;
		    font-size: 26px;
		    font-weight: bold;
		    font-family: 'Ubuntu', sans-serif;
			}
			
			.contact-title > button {
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				outline: none;
				border: none;
				background-color: transparent;

				color: #fff;
				color: #00ccbe;
		    font-size: 20px;
		    font-family: 'Ubuntu', sans-serif;
			}
				
				.contact-title > button:hover {
					color: #ffdc32;
				}
}