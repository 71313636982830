
.auth-container {  
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  overflow: hidden;
}

#introVideo { 
  position: absolute;
  height: 120%;
  width: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  
  display: block;
  background-size: cover;
}

.auth-logo-container {
  position: absolute;
  right: 0px;
  bottom: 0px;
  
  margin: 30px;
}

.auth-logo-container > img {
  height: 70px;
}


#auth-isloading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  width: 500px;
  height: 60vh;

  border-radius: 5px;

  background-color: rgba(250,250,250,0.8);
}

  #auth-isloading > h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    
    height: 100%;

    font-family: 'Libre Baskerville', sans-serif;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
  }

.auth-form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500px;
  padding: 20px 40px;

  transform: translate(-50%, -50%);

  border-radius: 5px;

  background-color: rgba(250,250,250,0.8);
}

  .auth-form-container > h1 {
    margin-top: 30px;

    font-family: 'Libre Baskerville', sans-serif;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
  }

  .auth-form-container > p {
    margin-left: 4px;
    margin-top: 6px;
    margin-bottom: 20px;

    color: #374258;
    font-family: 'Libre Baskerville', sans-serif;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
  }

  .auth-form-container > p > span {
    color: #830004;
    font-weight: bold;
  }

    .auth-form-two {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      width: 100%;
      height: 100%;
      margin: 10px 0px;
    }

    .auth-form-input-container {
      width: 100%;
      height: 100%;
      
      margin: 10px 0px;
    }

      .auth-form-label {
        padding-left: 4px;

        font-family: 'Libre Baskerville', sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
      }

      .auth-form-input {
        display: block;
        width: 100%;
        padding-left: 14px;
        margin-top: 6px;

        outline: none;
        border: none;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        border-bottom: 2px solid rgb(173, 185, 201);

        text-overflow: ellipsis;
        overflow: hidden; 

        background-color: rgba(250,250,250,0.8);

        line-height: 40px;
        font-family: 'Libre Baskerville', sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
      }
      
        .auth-form-input:hover {
          border-bottom: 2px solid rgb(131,0,4);
        }

    #auth-form-phonenumber-container {
      display: grid;
      grid-template-columns: 80px auto;
      grid-gap: 0px;

      height: 40px;

      margin-top: 6px;

      overflow: hidden;

      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      overflow: hidden;

      border-bottom: 2px solid rgb(173, 185, 201);
    }

    #auth-form-phonenumber-container:hover {
      border-bottom: 2px solid rgb(131,0,4);
    }

      #auth-form-phonenumber-container > select {
        width: auto;
        height: 100%;

        text-align: center;
        text-align-last: center;

        border: none;
        background-color: rgb(131,0,4);

        color: #fff;
        font-family: 'Libre Baskerville', sans-serif;
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
      }

      .auth-form-phonenumber-input {
        display: flex;

        height: 100%;
        width: 100%;

        padding-left: 10px;
        
        outline: none;
        border: none;

        text-overflow: ellipsis;
        overflow: hidden; 

        background-color: transparent;
        background-color: rgba(250,250,250,0.8);

        letter-spacing: 1px;
        font-size: 12px;
        font-family: 'Libre Baskerville', sans-serif;
        font-family: 'Montserrat', sans-serif;
      }
      

  .auth-form-buttons-container {
    display: grid;
    grid-template-columns: auto 120px;
    grid-gap: 10px;
    align-items: center;

    width: 100%;

    padding: 2px;

    margin-top: 30px;
  }
    
    .auth-form-buttons-container > p {
      color: #374258;

      font-size: 12px;
      font-family: 'Libre Baskerville', sans-serif;
      font-family: 'Montserrat', sans-serif;
    }

      .auth-form-buttons-container > p > a {
        cursor: pointer;
        color: rgb(131,0,4);
      }
        
        .auth-form-buttons-container > p > a:hover {
          border-bottom: 1px solid rgb(131,0,4);
        }

    .auth-form-buttons-container > a {
      cursor: pointer;
      color: #374258;

      font-size: 12px;
      font-family: 'Libre Baskerville', sans-serif;
      font-family: 'Montserrat', sans-serif;
    }

    .auth-form-buttons-container > a:hover {
      color: rgb(131,0,4);
    }

    .auth-form-button {
      cursor: pointer;
      height: 40px;
      width: 120px;

      border: none;
      border-radius: 4px;

      background-color: #374258;
      background-color: rgb(131,0,4);

      color: rgb(250,250,250);

      font-size: 13px;
      font-family: 'Libre Baskerville', sans-serif;
      font-family: 'Montserrat', sans-serif;
    }


      .auth-form-button:hover {
        background-color: rgba(131,0,4,0.8);  
      }

      .auth-form-button-disabled {
        height: 40px;
        width: 120px;

        border: 1px solid #374258;
        border-radius: 4px;

        background-color: #e7e3ee;

        color: #374258;
        font-size: 13px;
        font-family: 'Libre Baskerville', sans-serif;
        font-family: 'Montserrat', sans-serif;
      }


  #auth-form-verify-code {
    display: grid;
    grid-template-columns: auto 120px;
    grid-gap: 10px;

    width: 100%;
  }

    #auth-form-verify-code > input[type=numeric] {
      height: 40px;
      width: 100%;
      padding-left: 14px;

      text-overflow: ellipsis;
      overflow: hidden; 

      background-color: rgba(250,250,250,0.8);

      font-family: 'Libre Baskerville', sans-serif;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
    }
    
      #auth-form-verify-code > input[type=numeric]:hover {
        border-bottom: 2px solid rgb(131,0,4);
      }

    #auth-form-verify-code > button {
      cursor: pointer;
      height: 100%;
      width: 120px;

      border: 2px solid rgb(131,0,4);
      border-radius: 4px;
      background-color: transparent;

      text-align: center;
      color: rgb(131,0,4);
      font-size: 13px;
      font-family: 'Libre Baskerville', sans-serif;
      font-family: 'Montserrat', sans-serif;
    }

      #auth-form-verify-code > button:hover {
        border: 2px solid rgba(131,0,4,0.7);
        color: rgba(131,0,4,0.7);
      }


  .auth-form-forgot-password {
    margin-top: 5px;

    color: #374258;

    font-size: 12px;
    font-family: 'Libre Baskerville', sans-serif;
    font-family: 'Montserrat', sans-serif;
  }
  
    .auth-form-forgot-password > a {
      cursor: pointer;
      color: rgb(131,0,4);
    }
      
      .auth-form-forgot-password > a:hover {
        border-bottom: 1px solid rgb(131,0,4);
      }


