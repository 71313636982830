
@media (max-width: 641px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */ 

  .navbar {
  	position: sticky;
  	z-index: 888;
  	top: 0;
  	height: 70px;
  	width: 100vw;

  	display: flex;
  	align-items: center;
    justify-content: center;

  	background-color: rgb(40,40,40);
  }

  .navbar-logo {
      cursor: pointer;
      
      display: flex;
      justify-content: flex-start;
      align-items: center;

      outline: none;
      border: none;
      background-color: transparent;

      color: #00ccbe;
      color: #fff;
      font-size: 28px;
      font-family: 'Menlo', sans-serif;
    }
      
      .navbar-logo > span {
      	pointer-events: none;
        margin-right: 4px;
        padding: 4px 6px;
        border-radius: 8px;

        background: rgba(92,15,139,1);
        background: linear-gradient(180deg, rgba(92,15,139,0.7) 25%, rgba(92,15,139,1) 100%);
        
        color: #fff;
      }

      .navbar-logo:hover {
        color: #ffdc32;
        color: #5c0f8b;
        color: #00ccbe;
      }

      	.navbar-logo:hover > span {
  	      background: rgba(92,15,139,1);
  	      background: linear-gradient(180deg, rgba(92,15,139,0.7) 25%, rgba(92,15,139,1) 100%);
  	      background: rgb(0,204,190);
  	      background: linear-gradient(180deg, rgba(0,204,190,0.7) 25%, rgba(0,204,190,1) 100%);
  	    }

}

@media (max-width: 961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width: 961px)  { 
  /* desktops */ 

  .navbar {
    position: sticky;
    z-index: 888;
    top: 0;
    height: 80px;
    width: 100vw;

    display: flex;
    align-items: center;

    padding: 0px 20px;

    background: #fcfcfc;
  }

  .navbar-logo {
      cursor: pointer;
      
      display: flex;
      justify-content: flex-start;
      align-items: center;

      outline: none;
      border: none;
      background-color: transparent;

      color: #00ccbe;
      color: #fff;
      font-size: 28px;
      font-weight: 600;
      font-family: 'Menlo', sans-serif;
      font-family: 'Ubuntu', sans-serif;
    }
      
      .navbar-logo > span {
        pointer-events: none;
        margin-right: 4px;
        padding: 4px 6px;
        border-radius: 8px;

        background: rgba(92,15,139,1);
        background: linear-gradient(180deg, rgba(92,15,139,0.7) 25%, rgba(92,15,139,1) 100%);
        
        color: #fff;
      }

      .navbar-logo:hover {
        color: #ffdc32;
        color: #5c0f8b;
        color: #00ccbe;
      }

        .navbar-logo:hover > span {
          background: rgba(92,15,139,1);
          background: linear-gradient(180deg, rgba(92,15,139,0.7) 25%, rgba(92,15,139,1) 100%);
          background: rgb(0,204,190);
          background: linear-gradient(180deg, rgba(0,204,190,0.7) 25%, rgba(0,204,190,1) 100%);
        }

  .navbar.transparent {
    background: transparent;
  }
}