@import url('https://fonts.googleapis.com/css?family=Ubuntu');

/*
  rgb(0,0,0) || #000000
  rgb(92,15,139) || #5c0f8b
  rgb(0,204,190) || #00ccbe
  rgb(255,220,50) || #ffdc32
  rgb(0,85,184) || #0055b8
*/

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  margin: 0;

  background-color: #000;
}

