@media (max-width: 641px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */ 

	.contact-input {
		position: relative;
	}

	.contact-input > button {
		margin-top: 4px;
		padding: 0px 10px;

		width: 100%;
		height: 30px;

		outline: none;
		border: 2px solid #fff;
		background-color: rgba(250,250,250,0.2);

		text-align: left;
		color: #fff;;
		font-size: 14px;
		font-family: 'Menlo', sans-serif;
	}
		
		.contact-input > button:hover {
			border: 2px solid #5c0f8b;
			border: 2px solid #00ccbe;

			background-color: rgba(0,204,190,0.3);
		}

	.contact-menu {
		position: absolute;
		z-index: 888;
		min-width: 60%;
		max-height: 144px;

		border-radius: 10px;
		
		overflow-y: scroll;
		background-color: rgb(92,15,139);
	}

		.contact-menu-ul {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			border-radius: 10px;
		}

		.contact-option {
			cursor: pointer;
			height: 36px;
			width: 100%;
			padding: 0px 10px;

			display: flex;
			align-items: center;
			justify-content: flex-start;

			white-space: nowrap;

			outline: none;
			border: none;
			border-radius: 0px;

			background-color: transparent;

			text-align: left;
			color: #fff;
	  	font-size: 14px;
	  	font-family: 'Menlo', sans-serif;
		}
			
			.contact-option.active {
				color: #fff;
				background-color: rgb(10,10,10,0.2);
			}

			.contact-option.active:active {
				color: #00ccbe;
				background-color: rgb(10,10,10,0.2);
			}
		
		.contact-arrow-up-wrapper {
			pointer-events: none;
			height: 24px;
			width: 24px;
			position: absolute;
			top: 0;
			left: 50%;
			margin-left: -5px;
		}
		
			.contact-arrow-up {
				cursor: pointer;
				position: fixed;

				color: #00ccbe;
				font-size: 15px;
			}
			
		.contact-arrow-down-wrapper {
			pointer-events: none;
			height: 24px;
			width: 24px;
			position: absolute;
			bottom: -10px;
			left: 50%;
			margin-left: -5px;

			display: flex;
			align-items: center;
			justify-content: center;
		}

			.contact-arrow-down {
				cursor: pointer;
				position: fixed;

				color: #00ccbe;
				font-size: 15px;
			}
}

@media (max-width: 961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width: 961px)  { 
  /* desktops */ 

	.contact-input {
		position: relative;
	}

	.contact-input > button {
		margin-top: 8px;
		padding: 0px 10px;

		width: 100%;
		height: 36px;

		outline: none;
		border: 2px solid #fff;
		background-color: rgba(250,250,250,0.2);

		text-align: left;
		color: #fff;;
		font-size: 14px;
		font-family: 'Menlo', sans-serif;
	}
		
		.contact-input > button:hover {
			border: 2px solid #5c0f8b;
			border: 2px solid #00ccbe;

			background-color: rgba(0,204,190,0.3);
		}

	.contact-menu {
		position: absolute;
		z-index: 888;
		min-width: 60%;
		max-height: 200px;

		border-radius: 10px;
		
		overflow-y: scroll;
		background-color: rgb(92,15,139);
	}

		.contact-menu-ul {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			border-radius: 10px;
		}

		.contact-option {
			cursor: pointer;
			height: 40px;
			width: 100%;
			padding: 0px 10px;

			display: flex;
			align-items: center;
			justify-content: flex-start;

			white-space: nowrap;

			outline: none;
			border: none;
			border-radius: 0px;

			background-color: transparent;

			text-align: left;
			color: #fff;
	  	font-size: 14px;
	  	font-family: 'Menlo', sans-serif;
		}
			
			.contact-option.active {
				color: #fff;
				background-color: rgb(10,10,10,0.2);
			}

			.contact-option.active:active {
				color: #00ccbe;
				background-color: rgb(10,10,10,0.2);
			}
		
		.contact-arrow-up-wrapper {
			pointer-events: none;
			height: 24px;
			width: 24px;
			position: absolute;
			top: 0;
			left: 50%;
			margin-left: -5px;
		}
		
			.contact-arrow-up {
				cursor: pointer;
				position: fixed;

				color: #00ccbe;
				font-size: 15px;
			}
			
		.contact-arrow-down-wrapper {
			pointer-events: none;
			height: 24px;
			width: 24px;
			position: absolute;
			bottom: -10px;
			left: 50%;
			margin-left: -5px;

			display: flex;
			align-items: center;
			justify-content: center;
		}

			.contact-arrow-down {
				cursor: pointer;
				position: fixed;

				color: #00ccbe;
				font-size: 15px;
			}

}