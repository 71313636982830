@media (max-width: 641px)  { 
	/* smartphones, iPhone, portrait 480x320 phones */ 
	
	.header {
		position: relative;
		z-index: 444;
		width: 100%;
		height: 100vh;
		margin-top: -70px;
		padding-top: 70px;

		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 25% auto 100px;
	}

	.header-video {
		position: absolute;
	  z-index: -1;
	  left: 0;
	  top: 0;
	  
		height: 100%;
	  width: 100%;
	}
		.header-video video {
			height: 100%;
	  	width: 100%;
			opacity: 86%;
			object-fit: cover;
	  	/*background-size: cover;*/
		}
	
	.header-roles {
		grid-row-start: 1;
		grid-row-end: 2;

		position: relative;
		left: 0;
		padding: 20px 0px;
		padding-left: 26px;
		width: 100vw;
		overflow-x: hidden;

		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}
		
		.header-role-wrapper {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			margin-left: -20px;
		}
		
		.header-role {
			cursor: pointer;
			transition-duration: 0.2s;

			padding: 4px 6px;

			outline: none;
			border: none;
			border-radius: 6px;

			font-size: 14px;
			font-family: 'Menlo', sans-serif;
		}


	.header-text {
		grid-row-start: 2;
		grid-row-end: 3;

		position: relative;
		width: 100vw;

		padding: 0px 20px;

		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.header-title {
		width: 100%;

    color: #000;
		color: #fff;
		line-height: 36px;
		text-align: center;
		font-size: 28px;
		font-family: 'Ubuntu', sans-serif;
	}
	
	.header-subtitle {
		margin-top: 0px;
		padding: 0px 20px;

		text-align: justify;
		color: #000;
		color: #fff;
		font-size: 16px;
		line-height: 26px;
		font-family: 'Menlo', sans-serif;
	}
	
		.header-subtitle > span {
			padding: 2px 4px;
			border-radius: 4px;

			color: #5c0f8b;
			font-weight: bold;

			background-color: rgb(250,250,250);
		}

	.header-action {
		padding-top: 20px;

		display: flex;
		align-items: flex-start;
		justify-content: center;
	}
		
		.header-action button {
			cursor: pointer;

			height: 50px;
			padding: 0px 20px;
	    
	    outline: none;
	    border: 3px solid #5c0f8b;
	    border: 3px solid #fff;
	    border-radius: 50px;

	    background-color: rgb(250,250,250);
	    background-color: #0055b8;
	    background-color: #5c0f8b;

	    color: #5c0f8b;
	    color: #ffdc32;
	    color: #fff;
	    font-size: 20px;
	    font-weight: bold;
			font-family: 'Ubuntu', sans-serif;

	    -webkit-box-shadow: -1px 0px 1px 1px rgba(0,0,0,0.3);
	    -moz-box-shadow: -1px 0px 1px 1px rgba(0,0,0,0.3);
	    box-shadow: -1px 0px 1px 1px rgba(0,0,0,0.3);
		}
		
			.header-action button span {
				pointer-events: none;
				font-weight: normal;
				font-size: 18px;
			}

			.header-action button:hover {
				border: 3px solid #5c0f8b;

		    background-color: #5c0f8b;
		    background-color: rgb(250,250,250);

		    color: #5c0f8b;
			}

}

@media (max-width: 961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width: 961px)  { 
  /* desktops */ 
	
	.header {
		position: relative;
		width: 100%;
		height: 100vh;

		/*margin-top: -90px;*/
		padding-top: 90px;

		display: grid;
		grid-template-columns: 75% 25%;
		grid-template-rows: 70% 30%;
	}

	.header-video {
		position: absolute;
	  z-index: 0;
	  left: 0;
	  top: 0;
	  
		height: 100%;
	  width: 100%;
	}
		.header-video video {
			height: 100%;
	  	width: 100%;
			opacity: 86%;
			object-fit: cover;
	  	/*background-size: cover;*/
		}

	.header-shade {
		pointer-events: none;
		position: absolute;
		z-index: 99;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;

		background-color: rgb(30,30,30);
	}
		
	.header-action {
		grid-column-start: 1;
		grid-column-end: 2;
		grid-row-start: 2;
		grid-row-end: 3;
		position: relative;
		z-index: 3;

		margin-top: 20px;
		margin-left: 80px;

		display: flex;
		align-items: flex-start;
		justify-content: flex-start;

	}
		.header-action button {
			cursor: pointer;

			height: 50px;
			padding: 0px 20px;
	    
	    outline: none;
	    border: 3px solid #5c0f8b;
	    border: 3px solid #fff;
	    border-radius: 50px;

	    background-color: rgb(250,250,250);
	    background-color: #0055b8;
	    background-color: #5c0f8b;

	    color: #5c0f8b;
	    color: #ffdc32;
	    color: #fff;
	    font-size: 20px;
	    font-weight: bold;
			font-family: 'Ubuntu', sans-serif;

	    -webkit-box-shadow: -1px 0px 1px 1px rgba(0,0,0,0.3);
	    -moz-box-shadow: -1px 0px 1px 1px rgba(0,0,0,0.3);
	    box-shadow: -1px 0px 1px 1px rgba(0,0,0,0.3);
		}
		
	.header-text {
		position: relative;
		padding-left: 80px;
		padding-right: 160px;
		z-index: 3;

		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
		
		.header-title {
			margin-bottom: -50px;
			display: flex;
			align-items: flex-end;
		}
		
			.header-title > p {
				color: #000;
				color: #fff;
				font-size: 40px;
				font-weight: bold;
				font-family: 'Ubuntu', sans-serif;
				padding-right: 200px;
			}
		
				.header-title > p > span {
					color: #00ccbe;
					font-weight: bold;
				}
		
		.header-subtitle {
			display: flex;
			align-items: flex-end;
		}
		
			.header-subtitle > p {
				text-align: justify;
				color: #000;
				color: #fff;
				font-size: 24px;
				line-height: 34px;
				font-family: 'Menlo', sans-serif;
			}

				.header-subtitle > p > span {
					padding: 2px 4px;
					border-radius: 4px;

					color: #5c0f8b;
					font-weight: bold;

					background-color: rgb(250,250,250);
				}

	
	.header-roles {
		grid-column-start: 2;
		grid-column-end: 3;
		grid-row-start: 1;
		grid-row-end: 3;

		padding: 20px 0px;

		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(9, 1fr);
	}
		
		.header-role-wrapper {
			width: 100%;

			display: flex;
			align-items: center;
			justify-content: center;
		}

		.header-role {
			cursor: pointer;
			transition-duration: 0.2s;

			padding: 6px 16px;

			outline: none;
			border: none;
			border-radius: 6px;

			font-size: 18px;
			font-family: 'Menlo', sans-serif;
		}


	.header-counters {
		position: absolute;
		right: 0;
		top: 126px;
		height: calc(100vh - 226px);
		width: 200px;

		display: grid;
		grid-template-rows: repeat(6, 1fr);
	}

		.header-counters > p {
			margin: 20px 0px;
			padding-left: 16px;

			display: flex;
			align-items: center;
			justify-content: flex-start;

			border-left: 4px solid rgb(250,250,250);

			color: #fff;
			font-size: 18px;
			font-family: 'Menlo', sans-serif;
		}
		
			.header-counters > p > span {
				font-size: 16px;
				font-family: 'Menlo', sans-serif;
			}

}