@media (max-width: 641px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */ 

	.contact-search > input {
		border-radius: 20px;
	}

	.contact-menu-search {
		position: absolute;
		z-index: 888;
		min-width: 110%;
		max-height: 144px;

		border-radius: 10px;
		
		overflow-y: scroll;
		background-color: rgb(92,15,139);
	}

		.contact-menu-search-ul {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			border-radius: 10px;
		}

		.contact-search-option {
			cursor: pointer;
			height: 36px;
			width: 100%;
			padding: 0px 10px;

			display: flex;
			align-items: center;

			white-space: nowrap;

			outline: none;
			border: none;
			border-radius: 0px;

			background-color: transparent;

			text-align: left;
			color: #fff;;
	  	font-size: 14px;
	  	font-family: 'Menlo', sans-serif;
		}
			.contact-search-option:active {
				color: #00ccbe;
				background-color: rgb(10,10,10,0.2);
			}
		
		.contact-menu-arrow-up-wrapper {
			pointer-events: none;
			position: absolute;
			top: 0;
			left: 50%;
		}
		
			.contact-menu-arrow-up {
				cursor: pointer;

				position: fixed;
				height: 24px;
				width: 24px;

				display: flex;
				align-items: center;
				justify-content: center;

				color: #00ccbe;
				font-size: 15px;
			}
			
		.contact-menu-arrow-down-wrapper {
			pointer-events: none;
			position: absolute;
			bottom: 24px;
			left: 50%;
		}

			.contact-menu-arrow-down {
				cursor: pointer;

				position: fixed;
				height: 24px;
				width: 24px;

				display: flex;
				align-items: center;
				justify-content: center;

				color: #00ccbe;
				font-size: 15px;
			}
}

@media (max-width: 961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width: 961px)  { 
  /* desktops */ 

	.contact-search > input {
		border-radius: 20px;
	}

	.contact-menu-search {
		position: absolute;
		z-index: 888;
		min-width: 110%;
		max-height: 200px;

		border-radius: 10px;
		
		overflow-y: scroll;
		background-color: rgb(92,15,139);
	}

		.contact-menu-search-ul {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			border-radius: 10px;
		}

		.contact-search-option {
			cursor: pointer;
			height: 40px;
			width: 100%;
			padding: 0px 10px;

			display: flex;
			align-items: center;

			white-space: nowrap;

			outline: none;
			border: none;
			border-radius: 0px;

			background-color: transparent;

			text-align: left;
			color: #fff;;
	  	font-size: 14px;
	  	font-family: 'Menlo', sans-serif;
		}
			
			.contact-search-option.active {
				color: #fff;
				background-color: rgb(10,10,10,0.2);
			}

			.contact-search-option.active:active {
				color: #00ccbe;
				background-color: rgb(10,10,10,0.2);
			}
		
		.contact-menu-arrow-up-wrapper {
			pointer-events: none;
			position: absolute;
			top: 0;
			left: 50%;
		}
		
			.contact-menu-arrow-up {
				cursor: pointer;

				position: fixed;
				height: 24px;
				width: 24px;

				display: flex;
				align-items: center;
				justify-content: center;

				color: #00ccbe;
				font-size: 15px;
			}
			
		.contact-menu-arrow-down-wrapper {
			pointer-events: none;
			position: absolute;
			bottom: 24px;
			left: 50%;
		}

			.contact-menu-arrow-down {
				cursor: pointer;

				position: fixed;
				height: 24px;
				width: 24px;

				display: flex;
				align-items: center;
				justify-content: center;

				color: #00ccbe;
				font-size: 15px;
			}
}
