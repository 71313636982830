/*
	Colors
	rgba(92,15,139,1) #5c0f8b
	rgba(0,204,190,1) #00ccbe
	rgba(255,220,50,1) #ffdc32
	rgba(0,85,184,1) #0055b8
*/

@media (max-width: 641px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */ 

  .home {
		position: relative;
		width: 100vw;
		height: 100vh;
		overflow: auto;
		overflow-x: hidden;
	}

}

@media (max-width: 961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width: 961px)  { 
  /* desktops */ 

  .home {
		position: relative;
		width: 100vw;
		height: 100vh;
		overflow: auto;
	}
	.home-content {
		position: relative;
		width: 100%;
	}

}
