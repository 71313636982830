@media (max-width: 641px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */ 

  .about {
    position: relative;
    width: 100vw;
    z-index: 555;
  }

  .about-content {
    position: relative;
    margin-top: calc(-100vh + 70px);
    overflow-y: visible;
  }

    .about-content h3 {
      width: 100vw;
      height: 60px;

      display: flex;
      align-items: flex-end;
      justify-content: center;
      
      text-align: center;
      color: #000;
      color: #fff;
      font-size: 26px;
      font-family: 'Ubuntu', sans-serif;
    }

  .about-cases {
    margin-top: 50px;
    padding: 0px 10px;
  }

    .about-case {
      position: relative;
      height: 100%;
      width: 100vw;
      margin-top: -6px;
    }
    
      .about-case-content {
        position: absolute;
        top: 0;

        padding-left: 60px;
        padding-right: 40px;

        display: grid;
        grid-template-columns: 50% auto;
        grid-template-rows: 80px auto;
      }

      .about-case.active > .about-case-content {
        padding-right: 60px;
        padding-left: 20px;
        
        grid-template-columns: 50% auto;
        padding-right: 60px;
        padding-left: 20px;
      }

        .about-case-title {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          margin-top: -10px;
          padding-left: 10px;

          color: #fff;
          font-size: 24px;
          font-weight: bold;
          font-family: 'Ubuntu', sans-serif;
        }
        
          .about-case.active .about-case-title {
            justify-content: flex-end;

            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 2;

            padding-right: 20px;
            padding-left: 0px;

            text-align: right;
          }
        
        .about-case-desc {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 2;
          grid-row-end: 3;

          height: 100%;
          width: 100%;
          margin-top: 30px;
          padding-left: 16px;

          display: flex;
          align-items: flex-start;

          text-align: justify;
          line-height: 26px;
          color: #fff;
          font-size: 14px;
          font-family: 'Menlo', sans-serif;
        }

          .about-case.active .about-case-desc {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 2;
            grid-row-end: 3;

            padding-left: 0px;
            padding-right: 16px;
          }
          

        .about-lottie-container {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 2;

          margin-top: 0px;
          height: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
        }
          .about-case.active .about-lottie-container {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
          }

      .about-chip {
        position: relative;
        top: 0px;
        left: 0px;
        width: 100vw;
      }
      
        .about-case.active > .about-chip {
          left: -19px;
          width: 100vw;

          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }

        .about-chip > img {
          width: calc(100vw - 40px);
        }


  .about-figures {
    position: sticky;
    z-index: -1;
    top: 70px;
    width: 100vw;
    height: calc(100vh - 70px);

    background-color: rgba(92,15,139,1);
  }

  .about-dots {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }

    .about-dots > img {
      height: 90px;
      width: auto;
    }

  .about-dots2 {
    display: none;
    position: absolute;
    top: 100px;
    left: 50px;
  }

    .about-dots2 > img {
      height: 340px;
      width: auto;
    }

  .about-dots3 {
    position: absolute;
    top: 10px;
    right: 10px;
  }

    .about-dots3 > img {
      height: 220px;
      width: auto;
    }

  .about-square0 {
    position: absolute;
    z-index: -1;
    top: 0px;
    right: 0px;
    height: 50vh;
    width: 50vw;

    background: linear-gradient(180deg, rgba(72,5,119,1) 0%, rgba(82,10,129,1) 20%, rgba(92,15,139,1) 100%);
  }

  .about-square1 {
    position: absolute;
    top: 30px;
    left: 30px;
    height: 40px;
    width: 40px;

    background-color: #ffdc32;
    background-color: rgba(255,220,50,0.9);
  }

  .about-square2 {
    display: none;
    position: absolute;
    bottom: 120px;
    left: 120px;
    height: 40px;
    width: 120px;

    background-color: #ffdc32;
    background-color: rgba(255,220,50,0.9);
  }

  .about-square3 {
    display: none;
    position: absolute;
    right: 0px;
    bottom: 190px;
    height: 80px;
    width: 160px;

    background-color: #ffdc32;
    background-color: rgba(255,220,50,0.9);
  }

  .about-square4 {
    display: none;
    position: absolute;
    right: 0px;
    bottom: 30px;
    height: 160px;
    width: 40px;

    background-color: #ffdc32;
    background-color: rgba(255,220,50,0.9);
  }

  .about-square5 {
    display: none;
    position: absolute;
    left: 24%;
    top: -40px;
    height: 80px;
    width: 120px;

    background-color: #ffdc32;
    background-color: rgba(255,220,50,1);
  }

  .about-square6 {
    display: none;
    position: absolute;
    right: 24%;
    bottom: -40px;
    height: 80px;
    width: 120px;

    background-color: #ffdc32;
    background-color: rgba(255,220,50,1);
  }
}

@media (max-width: 961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width: 961px)  { 
  /* desktops */ 

  .about {
    position: relative;
    top: 0;
    width: 100vw;
    scroll-margin-top: 80px;
    z-index: 555;
  }

  .about-content {
    position: relative;
    margin-top: calc(-100vh + 80px);
    overflow-y: visible;
  }
  
    /*.about-content.active {
      overflow-y: scroll;
    } */

    .about-content h3 {
      position: relative;
      height: 84px;
      width: 100%;

      display: flex;
      align-items: flex-end;
      justify-content: center;

      color: #000;
      color: #fff;
      font-size: 34px;
      font-weight: bold;
      font-family: 'Ubuntu', sans-serif;
    }

    .about-cases {
      margin-top: 20px;
      padding: 20px 200px;
    }

      .about-case {
        position: relative;
        height: 100%;
        margin-top: -6px;
      }
      
        .about-case-content {
          position: absolute;
          top: 0;
          height: 60%;

          display: grid;
          grid-template-columns: 65% auto;
          grid-template-rows: 80px calc(100% - 80px);
          grid-gap: 20px;
        }

          .about-case.active > .about-case-content {  
            grid-template-columns: auto 65%;
          }

        .about-case-title {
          padding-top: 20px;

          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          padding-left: 80px;

          color: #fff;
          font-size: 32px;
          font-family: 'Ubuntu', sans-serif;
        }
        
          .about-case.active .about-case-title {
            justify-content: flex-end;
            padding-right: 80px;
            padding-left: 0px;

            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 2;

            text-align: right;
          }

          .about-case:hover .about-case-title {
            transition-duration: 0.1s;
            color: #00ccbe;
            font-weight: bold;
          }
        
        .about-case-desc {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 2;
          grid-row-end: 3;

          width: 100%;
          padding-left: 60px;

          display: flex;
          align-items: center;

          text-align: justify;
          line-height: 30px;
          color: #fff;
          font-size: 20px;
          font-family: 'Menlo', sans-serif;
        }

          .about-case.active .about-case-desc {
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 2;
            grid-row-end: 3;

            padding-right: 60px;
            padding-left: 0px;
          }
          

        .about-lottie-container {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 3;

          display: flex;
          align-items: center;
          justify-content: center;
        }
          .about-case.active .about-lottie-container {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 3;
          }

          .about-case:hover > .about-lottie-container {
            transition-duration: 0.3s;
            height: 34vh;
          }

        .about-chip {
          position: relative;
          top: 0;
          left: -14px;
        }
        
          .about-case.active > .about-chip {
            left: 18px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
          }

          .about-chip > img {
            width: calc(100vw - 400px);
          }

  .about-figures {
    position: sticky;
    z-index: -1;
    top: 80px;
    width: 100vw;
    height: calc(100vh - 80px);

    background-color: rgba(92,15,139,1);
  }
  
    .about-dots {
      position: absolute;
      bottom: 50px;
      left: 50px;
    }

      .about-dots > img {
        height: 90px;
        width: auto;
      }

    .about-dots2 {
      position: absolute;
      top: 100px;
      left: 50px;
    }

      .about-dots2 > img {
        height: 340px;
        width: auto;
      }

    .about-dots3 {
      position: absolute;
      top: 50px;
      right: 50px;
    }

      .about-dots3 > img {
        height: 220px;
        width: auto;
      }

    .about-square0 {
      position: sticky;
      z-index: -1;
      top: 0px;
      right: 0px;
      height: 50vh;
      width: 50vw;

      background: linear-gradient(180deg, rgba(72,5,119,1) 0%, rgba(82,10,129,1) 20%, rgba(92,15,139,1) 100%);
    }

    .about-square1 {
      position: absolute;
      top: 50px;
      left: 50px;
      height: 40px;
      width: 40px;

      background-color: #ffdc32;
      background-color: rgba(255,220,50,1);
    }

    .about-square2 {
      position: absolute;
      bottom: 120px;
      left: 120px;
      height: 40px;
      width: 120px;

      background-color: #ffdc32;
      background-color: rgba(255,220,50,0.9);
    }

    .about-square3 {
      display: none;
      position: absolute;
      right: 0px;
      bottom: 190px;
      height: 80px;
      width: 160px;

      background-color: #ffdc32;
      background-color: rgba(255,220,50,0.9);
    }

    .about-square4 {
      position: absolute;
      right: 0px;
      bottom: 30px;
      height: 160px;
      width: 40px;

      background-color: #ffdc32;
      background-color: rgba(255,220,50,0.9);
    }

    .about-square5 {
      position: absolute;
      left: 24%;
      top: -40px;
      height: 80px;
      width: 120px;

      background-color: #ffdc32;
      background-color: rgba(255,220,50,1);
    }

    .about-square6 {
      position: absolute;
      right: 24%;
      bottom: -40px;
      height: 80px;
      width: 120px;

      background-color: #ffdc32;
      background-color: rgba(255,220,50,1);
    }
}
