
.menu {
	min-height: calc(100vh - 70px);
	width: 200px;

	display: flex;
	align-items: center;

	padding: 0px 20px;

	background-color: rgb(10,10,20);
}
