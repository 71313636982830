@media (max-width: 641px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */ 

  .navcontainer {
    transition-duration: 0.3s;
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    z-index: 888;
    width: 100vw;
    height: 70px;
    padding: 0px 20px;

    display: grid;
    grid-template-columns: auto 100px;
    align-items: center;

    background-color: rgb(250,250,250);
    border-bottom: 2px solid rgba(0,204,190);
  }
  
    .navcontainer.active {
      background-color: rgb(250,250,250);

      -webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.6);
      -moz-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.6);
      box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.6);
    }

  .nav-submenu {
    display: none;
  }

  .logo {
    cursor: pointer;
    
    display: flex;
    align-items: center;
    justify-content: flex-start;

    color: #00ccbe;
    color: #fff;
    color: #000;
    font-size: 28px;
    font-family: 'Menlo', sans-serif;
  }
    
    .logo > span {
      margin-right: 4px;
      padding: 4px 6px;
      border-radius: 8px;

      background: rgba(92,15,139,1);
      background: linear-gradient(180deg, rgba(92,15,139,0.7) 25%, rgba(92,15,139,1) 100%);
      
      color: #fff;
    }

    .navcontainer.active > .logo {
      color: #000;
    }

  .menu-icon {
    cursor: pointer;
    
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    color: #000;
    font-size: 22px;
  }

  .nav-menu {
    transition-duration: 0.2s;
    position: absolute;
    top: 70px;
    left: -100vw;
    width: 100vw;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);

    background-color: rgba(20,20,20,1);
    background-color: rgba(92,15,139,1);
    background-color: rgba(0,204,190,1);
    background-color: rgba(250,250,250);

    overflow: hidden;
  }
  
    .nav-menu.active {
      transition-duration: 0.3s;
      left: 0px;
    }

  .nav-links {
    cursor: pointer;
    
    width: 100%;
    height: 60px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-bottom: 1px solid rgba(0,204,190);

    text-decoration: none;
  }
    .nav-links.active {
      background: rgba(0,204,190,1);
    }
    .nav-links:active {
      background-color: rgba(0,204,190,1);
    }
  
    #nav-links-last {
      border: none;
    }

    .nav-links span {
      pointer-events: none;
      color: #fff;
      color: #000;
      font-size: 18px;
      font-family: 'Ubuntu', sans-serif;
    }

    .nav-links:active span {
      color: #ffdc32;
      border-bottom: 1px solid #ffdc32;
    }

  .navcontainer.transparent {
    background-color: transparent;
  }
    .navcontainer.transparent .logo {  
      color: #fff;
    }
    .navcontainer.transparent > .menu-icon {
      color: #fff;
    }
    .navcontainer.transparent .nav-links {
      color: #fff;
    }

}
@media (max-width: 961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }

@media (min-width: 961px)  { 
  /* desktops */ 
  .navcontainer {
    transition-duration: 0.3s;
    position: fixed;
    top: 0px;
    z-index: 888;
    width: 100vw;
    height: 80px;
    padding: 0px 30px;

    display: flex;
    align-items: center;

    background-color: rgb(250,250,250);
    border-bottom: 2px solid rgba(0,204,190);
  }

  .logo {
    cursor: pointer;
    
    display: flex;
    justify-content: flex-start;
    align-items: center;

    color: #00ccbe;
    color: #fff;
    color: #000;
    font-size: 28px;
    font-weight: 600;
    font-family: 'Menlo', sans-serif;
    font-family: 'Ubuntu', sans-serif;
  }
    .logo > span {
      margin-right: 6px;
      padding: 4px 8px;
      border-radius: 8px;

      background: rgba(92,15,139,1);
      background: linear-gradient(180deg, rgba(92,15,139,0.7) 25%, rgba(92,15,139,1) 100%);
      
      color: #fff;
    }

  .menu-icon {
    display: none;
  }


  .nav-submenu {
    display: flex;
    align-items: center;
    grid-gap: 40px;
    width: 100%;

    display: none;
  }

    .nav-submenu > div {
      cursor: pointer;
      border-right: 1px solid rgba(0,204,190,0.4);
      border-right: 1px solid rgba(150,150,150,0.4);

      text-align: center;
      color: #fff;
      font-size: 14px;
      font-family: 'Ubuntu', sans-serif;
    }
    
      .navcontainer:hover > .nav-submenu {
        border-bottom: 1px solid rgba(0,204,190,0.4);
        border-bottom: 1px solid rgba(150,150,150,0.4);
      }
      
      .navcontainer:hover > .nav-submenu > div {
        color: #000;
      }
      
        .navcontainer:hover > .nav-submenu > div:hover {
          color: #5c0f8b;
        }

    .nav-submenu.active {
      transition-duration: 0.2s; 
      display: none;
    }

  .nav-blocks {
    height: 100%;
    display: flex;
    grid-gap: 40px;

    border-bottom: 1px solid rgba(150,150,150,0.4);
    border-bottom: 1px solid rgba(0,204,190,0.4);
  }
    .navcontainer:hover .nav-blocks {
      border: none;
    }

  .nav-menu {
    width: 100%;
    display: flex;
    grid-gap: 40px;
    align-items: center;
    justify-content: flex-end;
  }

    .nav-menu li {
      list-style-type: none;
    }

  .nav-links {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    
    height: 100%;

    color: #fff;
    color: #000;
    font-size: 16px;
    font-family: 'Ubuntu', sans-serif;
    text-decoration: none;
  }
    
    .nav-links > span {
      pointer-events: none;
      padding-bottom: 2px;
    }
    
    .nav-links.active > span {
      color: #00ccbe;
    }
    .nav-links:hover > span {
      border-bottom: 1px solid #000;
    }

  .navcontainer.active, .navcontainer:hover {    
  }
    .navcontainer.active > .nav-blocks {
      height: 70px;
    }

    .navcontainer.active > .logo {  
      color: #000;
    }

    .navcontainer.active > .logo:hover {  
      color: #ffdc32;
      color: #5c0f8b;
      color: #00ccbe;
    }

      .navcontainer.active > .logo:hover > span {  
        margin-right: 4px;
        padding: 4px 6px;
        border-radius: 8px;

        background: rgba(0,204,190,1);
        background: linear-gradient(180deg, rgba(0,204,190,0.7) 25%, rgba(0,204,190,1) 100%);
        
        color: #fff;
      }

      .navcontainer.active .nav-links {  
        color: rgb(10,10,10);
      }
        .navcontainer.active .nav-links:hover span {  
          color: #5c0f8b;
          border-bottom: 1px solid #5c0f8b;
        }
      
      .navcontainer.active .nav-links {  
        color: rgb(10,10,10);
      }
        .navcontainer.active .nav-links:hover > span {  
          color: #5c0f8b;
          border-bottom: 1px solid #5c0f8b;
        }

  .navcontainer.transparent {
    background-color: transparent;
  }
    .navcontainer.transparent .logo {  
      color: #fff;
    }
    .navcontainer.transparent .nav-links {
      color: #fff;
    }
}
