@media (max-width: 641px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */ 

  .tools {
    position: relative;
    z-index: 555;
    width: 100vw;
    height: calc(100vh - 70px - 70px);

    background-color: #00ccbe;
  }

  .tools-chip {
    z-index: 1;
    position: absolute;
    left: 10px;
    top: -3px;
  }

    .tools-chip > img {
      width: 80px;
    }

  .tools-title {
    width: 100vw;
    height: 80px;

    display: flex;
    align-items: flex-end;
    justify-content: center;
    
    text-align: center;
    color: #000;
    color: #fff;
    font-size: 26px;
    font-family: 'Ubuntu', sans-serif;
  }

    /*display: flex;
    justify-content: center;*/
  .tools_box {
    position: absolute;
    top: 80px;
    height: calc(100% - 80px);
    padding: 0px 16px;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 8px;
    align-items: center;
    justify-content: center;  

  }

  .myBubbleUI {
    width: 100vw;
    height: 100%;
  }

  .dummyBubble {
    cursor: pointer;
    width: 100%;
    height: 80px;
    padding-bottom: 4px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    
    outline: none;
    border: 2px solid #0055b8;
    border: 2px solid #fff;
    border-radius: 8px;

    background-color: rgba(50,50,50,0.8);
  }

    .dummyBubble:hover {
      background-color: rgba(20,20,20,0.8);
      border: 3px solid #5c0f8b;
    }

    .dummyBubble > img {
      pointer-events: none;
      width: 40%;
    }

    .dummyBubbleText {
      pointer-events: none;
      margin-top: 8px;

      color: #fff;
      font-size: 10px;
      font-family: 'Menlo', sans-serif;
    }
    
    .dummyBubble:hover> .dummyBubbleText {
      font-weight: bold;
    }


  .tools-figures {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .tools-dots {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }

    .tools-dots > img {
      height: 90px;
      width: auto;
    }

  .tools-dots2 {
    display: none;
    position: absolute;
    top: 100px;
    left: 50px;
  }

    .tools-dots2 > img {
      height: 340px;
      width: auto;
    }

  .tools-dots3 {
    position: absolute;
    top: 10px;
    right: 10px;
  }

    .tools-dots3 > img {
      height: 220px;
      width: auto;
    }

  .tools-square0 {
    position: absolute;
    z-index: -1;
    top: 0px;
    right: 0px;
    height: 50vh;
    width: 50vw;

    background: linear-gradient(180deg, rgba(0,164,150,1) 0%, rgba(0,184,170,1) 20%, rgba(0,204,190,1) 100%);
  }

  .tools-square1 {
    position: absolute;
    top: 30px;
    left: 30px;
    height: 40px;
    width: 40px;

    background-color: #ffdc32;
  }

  .tools-square2 {
    display: none;
    position: absolute;
    bottom: 190px;
    left: 120px;
    height: 40px;
    width: 120px;

    background-color: #ffdc32;
    background-color: rgba(255,220,50,0.9);
  }

  .tools-square3 {
    display: none;
    position: absolute;
    right: 0px;
    bottom: 190px;
    height: 80px;
    width: 160px;

    background-color: #ffdc32;
    background-color: rgba(255,220,50,0.9);
  }

  .tools-square4 {
    display: none;
    position: absolute;
    right: 0px;
    bottom: 30px;
    height: 160px;
    width: 40px;

    background-color: #ffdc32;
    background-color: rgba(255,220,50,0.9);
  }

  .tools-square5 {
    display: none;
    position: absolute;
    left: 24%;
    top: 0px;
    height: 40px;
    width: 120px;

    background-color: #ffdc32;
    background-color: rgba(255,220,50,1);
  }
}

@media (max-width: 961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width: 961px)  { 
  /* desktops */ 
  .tools {
    position: relative;
    z-index: 555;
    width: 100vw;
    height: calc(100vh - 80px - 70px);

    background-color: #00ccbe;
  }


  .tools-chip {
    z-index: 1;
    position: absolute;
    left: 180px;
    top: -3px;
  }

    .tools-chip > img {
      width: 80px;
    }

  .tools-title {
    position: relative;
    height: 84px;
    width: 100%;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    color: #000;
    color: #fff;
    font-size: 34px;
    font-weight: bold;
    font-family: 'Ubuntu', sans-serif;
  }

    /*display: flex;
    justify-content: center;*/
  .tools_box {
    position: absolute;
    top: 100px;
    width: 100%;
    padding: 0px 80px;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    align-items: center;
    justify-content: center;  

    height: calc(100% - 100px);
  }

  .myBubbleUI {
    width: 100vw;
    height: 100%;
  }

  .dummyBubble-container {
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  
  }

  .dummyBubble {
    cursor: pointer;
    width: 100px;
    height: 100px;
    
    outline: none;
    border: 2px solid #0055b8;
    border: 2px solid #fff;
    border-radius: 8px;

    background-color: rgba(50,50,50,0.8);
  }

    .dummyBubble:hover {
      background-color: rgba(20,20,20,0.8);
      border: 3px solid #5c0f8b;
    }

    .dummyBubble > img {
      pointer-events: none;
      width: 50%;
    }

    .dummyBubbleText {
      pointer-events: none;
      margin-top: 8px;

      color: #fff;
      font-size: 14px;
      font-family: 'Menlo', sans-serif;
    }
    
    .dummyBubble:hover> .dummyBubbleText {
      font-weight: bold;
    }


  .tools-figures {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .tools-dots {
    position: absolute;
    bottom: 50px;
    left: 50px;
  }

    .tools-dots > img {
      height: 90px;
      width: auto;
    }

  .tools-dots2 {
    position: absolute;
    top: 100px;
    left: 50px;
  }

    .tools-dots2 > img {
      height: 340px;
      width: auto;
    }

  .tools-dots3 {
    position: absolute;
    top: 50px;
    right: 50px;
  }

    .tools-dots3 > img {
      height: 220px;
      width: auto;
    }

  .tools-square0 {
    position: absolute;
    z-index: -1;
    top: 0px;
    right: 0px;
    height: 50vh;
    width: 50vw;

    background: linear-gradient(180deg, rgba(0,164,150,1) 0%, rgba(0,184,170,1) 20%, rgba(0,204,190,1) 100%);
  }

  .tools-square1 {
    position: absolute;
    top: 50px;
    left: 50px;
    height: 40px;
    width: 40px;

    background-color: #ffdc32;
  }

  .tools-square2 {
    position: absolute;
    bottom: 120px;
    left: 120px;
    height: 40px;
    width: 120px;

    background-color: #ffdc32;
  }

  .tools-square3 {
    position: absolute;
    right: 0px;
    bottom: 190px;
    height: 80px;
    width: 160px;

    background-color: #ffdc32;
  }

  .tools-square4 {
    position: absolute;
    right: 0px;
    bottom: 30px;
    height: 160px;
    width: 40px;

    background-color: #ffdc32;
  }

  .tools-square5 {
    position: absolute;
    left: 24%;
    top: 0px;
    height: 40px;
    width: 120px;

    background-color: #ffdc32;
  }

}
