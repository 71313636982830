@media (max-width: 641px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */ 

	.footer {
		position: relative;
		width: 100%;	
		height: 70px;	

		background-color: rgb(20,20,20);
	}

	.copyright {
		position: absolute;
		left: 10px;

		display: flex;
		align-items: center;


		height: 100%;

		color: #fff;
		font-size: 12px;
		font-family: 'Menlo', sans-serif;
	}

	.copyright > span {
		color: #00ccbe;
		font-weight: bold;
	}

	.social {
		position: absolute;
		right: 10px;
		top: 0px;

		display: flex;
		align-items: center;
		justify-content: flex-end;

		height: 100%;
	}

	.social > a {
		cursor: pointer;

		margin: 0px 10px;

		color: #000;
		color: #00ccbe;
		color: #fff;
		font-size: 18px;
		font-family: 'Ubuntu', sans-serif;
	}

}

@media (max-width: 961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width: 961px)  { 
  /* desktops */ 

	.footer {
		position: relative;
		width: 100%;	
		height: 70px;	

		padding: 0;

		background-color: rgb(20,20,20);
	}

	.copyright {
		position: absolute;
		left: 50px;
		height: 100%;

		display: flex;
		align-items: center;

		color: #fff;
		font-size: 14px;
		font-family: 'Ubuntu', sans-serif;
		font-family: 'Menlo', sans-serif;
	}

	.copyright > span {
		color: #00ccbe;
		font-weight: 600;
	}

	.social {
		position: absolute;
		right: 50px;
		top: 0px;

		display: flex;
		align-items: center;
		justify-content: flex-end;

		height: 100%;
	}

	.social > a {
		cursor: pointer;

		margin: 0px 10px;

		color: #000;
		color: #00ccbe;
		color: #fff;
		font-size: 20px;
		font-family: 'Ubuntu', sans-serif;
	}
}