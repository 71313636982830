.howto {
  position: relative;
  z-index: 666;
  width: 100vw;
  height: calc(100vh - 70px);
  scroll-margin-top: 80px;

  background-color: rgb(20,20,20);
}

@media (max-width: 641px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */ 

  .howto-content {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
  }

    .howto-content-title {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 240px;
      height: 100px;

      display: flex;
      align-items: flex-end;
      
      text-align: center;
      color: #000;
      color: #fff;
      font-size: 26px;
      font-family: 'Ubuntu', sans-serif;
    }

    .howto-steps {
      position: absolute;
      top: 120px;
      height: calc(100vh - 190px);
      padding: 20px 10px;

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
    }
    
    .howto-step-container {
      display: flex;
      width: 100%;
    }
    
    .howto-step {
      width: 100%;
      padding: 10px;

      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 25% 35% 40%;

      border-radius: 10px;
      background-color: rgba(250,250,250,0.2);
    }
      
    .howto-step-number {
      pointer-events: none;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      text-align: center;
      color: rgba(255,220,50,0.9);
      color: #5c0f8b;
      color: #fff;
      font-size: 40px;
      font-family: 'Menlo', sans-serif;
    }
    
    .howto-step-icon {
      pointer-events: none;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
      .howto-step-icon div {
        height: 50px;
        width: 50px;
        
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 100px;

        text-align: center;
        border: 4px solid rgba(255,220,50,0.9);

        background-color: transparent;
      }
      
        .howto-step-icon span {
          color: #fff;
          font-size: 24px;
        }
      
    .howto-step-desc {
      pointer-events: none;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 0px 10px;
    }
      
      .howto-step-desc p {
        text-align: center;
        color: #5c0f8b;
        color: #fff;
        font-size: 16px;
        font-family: 'Menlo', sans-serif;
      }


  .howto-colors {
    position: absolute;
    top: 0px;
    right: 520px;
    width: 300px;
    height: 300px;
  }
    
  .howto-figures {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .howto-dots {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }

    .howto-dots > img {
      height: 90px;
      width: auto;
    }

  .howto-dots2 {
    position: absolute;
    top: 100px;
    left: 30px;
    display: none;
  }

    .howto-dots2 > img {
      height: 340px;
      width: auto;
    }

  .howto-dots3 {
    position: absolute;
    top: 10px;
    right: 10px;
  }

    .howto-dots3 > img {
      height: 220px;
      width: auto;
    }

  .howto-square0 {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 50vh;
    width: 50vw;

    background: linear-gradient(180deg, rgb(10,10,10,1) 0%, rgb(15,15,15,1) 20%, rgba(20,20,20,1) 100%);
  }

  .howto-square1 {
    position: absolute;
    top: 30px;
    left: 30px;
    height: 40px;
    width: 40px;

    background-color: #ffdc32;
  }

  .howto-square2 {
    position: absolute;
    bottom: 120px;
    left: 120px;
    height: 40px;
    width: 120px;
    display: none;

    background-color: #ffdc32;
    background-color: rgba(255,220,50,1);
  }

  .howto-square3 {
    cursor: pointer;

    position: absolute;
    z-index: 333;
    right: 0px;
    bottom: 190px;
    height: 80px;
    width: 160px;

    display: none;
    align-items: center;
    justify-content: center;

    outline: none;
    border: none;

    background-color: #ffdc32;
    background-color: rgba(255,220,50,1);

    color: #fff;
    color: #000;
    font-size: 36px;
    font-weight: bold;
    font-family: 'Ubuntu', sans-serif;
  }

    .howto-square3:hover {
      font-style: italic;
    }

  .howto-square4 {
    display: none;

    position: absolute;
    right: 0px;
    bottom: 30px;
    height: 160px;
    width: 40px;

    background-color: #ffdc32;
    background-color: rgba(255,220,50,1);
  }


}

@media (max-width: 961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width: 961px)  { 
  /* desktops */ 

  .howto-content {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;

    padding: 50px 0px;
  }

    .howto-content-title {
      position: relative;
      height: 84px;
      width: 100%;

      display: flex;
      align-items: flex-end;
      justify-content: center;

      color: #000;
      color: #fff;
      font-size: 34px;
      font-weight: bold;
      font-family: 'Ubuntu', sans-serif;
    }


    .howto-steps {
      position: relative;
      padding: 20px 200px;
      height: calc(100% - 30px);

      display: grid;
      grid-template-columns: repeat(4, 1fr);
      align-items: center;
    }
    
    .howto-step-container {
      position: relative;
      margin-top: -100px;
      height: 100px;

      border-right: 1px solid #0055b8;
      border-right: 1px solid #5c0f8b;
    }
    
      .howto-step-container.active {
        border: none;
      }
    
    .howto-step {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      
      padding: 20px;

      display: flex;
      flex-direction: column;
      align-items: center;

      border: 0px solid #5c0f8b;
      background-color: rgba(250,250,250,0.3);
      background-color: rgb(250,250,250);
      background-color: #5c0f8b;
      background-color: transparent;
    }
    
      .howto-step-number {
        display: block;
        margin-bottom: 20px;
        width: 100%;

        text-align: center;
        color: rgba(255,220,50,0.9);
        color: #5c0f8b;
        color: #fff;
        font-size: 46px;
        font-family: 'Menlo', sans-serif;
      }
        .howto-step:hover > .howto-step-number {
          color: #ffdc32;
        }

      .howto-step-icon {
        height: 70px;
        width: 70px;
        margin-bottom: 30px;
        
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 100px;

        text-align: center;
        border: 4px solid #5c0f8b;
        border: 4px solid #0055b8;
        border: 4px solid #fff;
        border: 4px solid rgba(255,220,50,0.9);

        background-color: #5c0f8b;
        background-color: #ffdc32;
        background-color: transparent;
      }
      
        .howto-step:hover > .howto-step-icon {
          background-color: rgba(255,220,50,0.4);
        }
      
        .howto-step-icon div span {
          color: #fff;
          font-size: 30px;
        }

      .howto-step-desc {
        display: block;
        
        text-align: center;
        color: #5c0f8b;
        color: #fff;
        font-size: 22px;
        font-family: 'Menlo', sans-serif;
      }


  .howto-colors {
    position: absolute;
    top: 0px;
    right: 520px;
    width: 300px;
    height: 300px;
  }
    
  .howto-figures {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .howto-dots {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }

    .howto-dots > img {
      height: 90px;
      width: auto;
    }

  .howto-dots2 {
    position: absolute;
    top: 100px;
    left: 30px;
  }

    .howto-dots2 > img {
      height: 340px;
      width: auto;
    }

  .howto-dots3 {
    position: absolute;
    top: 50px;
    right: 50px;
  }

    .howto-dots3 > img {
      height: 220px;
      width: auto;
    }

  .howto-square0 {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 50vh;
    width: 50vw;

    background: linear-gradient(180deg, rgb(10,10,10,1) 0%, rgb(15,15,15,1) 20%, rgba(20,20,20,1) 100%);
  }

  .howto-square1 {
    position: absolute;
    top: 30px;
    left: 30px;
    height: 40px;
    width: 40px;

    background-color: #ffdc32;
    background-color: rgba(255,220,50,1);
  }

  .howto-square2 {
    position: absolute;
    bottom: 120px;
    left: 120px;
    height: 40px;
    width: 120px;

    background-color: #ffdc32;
    background-color: rgba(255,220,50,1);
  }

  .howto-square3 {
    cursor: pointer;

    position: absolute;
    z-index: 333;
    right: 0px;
    bottom: 190px;
    height: 80px;
    width: 160px;

    display: flex;
    align-items: center;
    justify-content: center;

    outline: none;
    border: none;

    background-color: #ffdc32;
    background-color: rgba(255,220,50,1);

    color: #fff;
    color: #000;
    font-size: 36px;
    font-weight: bold;
    font-family: 'Ubuntu', sans-serif;
  }

    .howto-square3:hover {
      font-style: italic;
    }

  .howto-square4 {
    position: absolute;
    right: 0px;
    bottom: 30px;
    height: 160px;
    width: 40px;

    background-color: #ffdc32;
    background-color: rgba(255,220,50,1);
  }

}
